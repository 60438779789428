import api from '@/utils/api'

export default {
  state: () => ({
    contractors: null,
    contractorStates: [],
    contractorAdminStatuses: {},
  }),
  mutations: {
    setContractors(state, payload) {
      state.contractors = payload
    },
    setContractorStates(state, payload) {
      state.contractorStates = payload
    },
    setContractorAdminStatuses(state, payload) {
      state.contractorAdminStatuses = payload
    },
  },
  getters: {
    contractorStatesTransformed: state => {
      return state.contractorStates.map(item => {
        return {
          key: item,
          label: item,
        }
      })
    },
    getContractorAdminStatusByKey: state => {
      return key => {
        return state.contractorAdminStatuses[key] || ''
      }
    },
    contractorAdminStatusesTransformed: state => {
      return Object.entries(state.contractorAdminStatuses).map(([key, value]) => {
        return {
          key: key,
          label: value,
        }
      })
    },
  },
  actions: {
    async fetchContractors({commit}, params) {
      if (params?.search === '') {
        delete params.search
      }
      const res = await api.http.get('/admin/contractors', {params})
      commit('setContractors', res?.data)
    },
    async fetchContractor(_, id) {
      const res = await api.http.get(`admin/contractors/${id}`)
      return res?.data?.data
    },
    async fetchContractorStates({commit}) {
      const res = await api.http.get('admin/contractors/states')
      commit('setContractorStates', res?.data)
    },
    async fetchContractorAdminStatuses({commit}) {
      const res = await api.http.get('admin/contractors/admin-statuses')
      commit('setContractorAdminStatuses', res?.data)
    },
    async changeContractorState(_, id) {
      const res = await api.http.get(`admin/contractors/approve/${id}`)
      return res?.data?.data
    },
    async deleteContractor({dispatch}, id) {
      const res = await api.http.delete(`admin/contractors/${id}`)
      dispatch('fetchContractors')
      return res?.data?.data
    },
    async updatePhone({dispatch}, {phone, id}) {
      const res = await  api.http.put(`admin/contractors/${id}/phone`, {phone})
      dispatch('fetchContractors')
      return res?.data?.data
    },
    async fetchContractorCategories() {
      const res = await api.http.get('tree-categories')
      return res?.data?.data
    },
    async updateContractorCategory(_, { contractorId, categoryId }) {
      const res = await api.http.put(`admin/contractors/${contractorId}/category`, {category_id: categoryId})
      return res?.data?.data
    },
    async saveAdminStatus(_, {id, status}) {
      await api.http.post(`admin/contractors/${id}/change-status`, {'admin_status': status})
    },
  },
  namespaced: true,
}
