<template>
  <div class="contractors-services">
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Услуги исполнителя</h2>
        <el-table
          :data="contractorsServices"
          style="width: 100%"
        >
          <el-table-column
            label="Название"
            prop="title"
            width="180"
          />
          <el-table-column
            label="Описание"
            prop="description"
          />
          <el-table-column
            label="Цена"
            prop="price"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.price !== null ? scope.row.price + ' ' + scope.row.currency : 'Договорная' }}
            </template>
          </el-table-column>
        </el-table>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Портфолио</h2>
        <div class="comparing-table__label">
          Изображения
          <div v-if="portfolioImages && portfolioImages.length > 1">
            <ImageViewer
              :images="portfolioImages"
              removing-allowed
            />
          </div>
          <div v-else>
            ---
          </div>
        </div>

        <div class="comparing-table__label">
          Ссылки на видео
          <div v-if="portfolioVideos && portfolioVideos.length > 1">
            <ul>
              <li v-for="(video, index) in portfolioVideos" :key="index">
                <a :href="video" target="_blank">{{ video }}</a>
              </li>
            </ul>
          </div>
          <div v-else>
            ---
          </div>
        </div>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Примеры работ</h2>
        <el-table
          :data="workExamples"
          style="width: 100%"
        >
          <el-table-column
            label="Название"
            prop="title"
            width="180"
          />
          <el-table-column
            label="Цена"
            prop="price"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.price !== null ? scope.row.price + ' ' + scope.row.currency : 'Договорная' }}
            </template>
          </el-table-column>
          <el-table-column
            label="Изображения"
            prop="work_example_images"
          >
            <template slot-scope="scope">
              <ImageViewer :images="scope.row.work_example_images" />
            </template>
          </el-table-column>
        </el-table>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Навыки</h2>
        <div>
          <el-button
            v-for="tag in tags"
            :key="tag.id"
            style="margin: 10px 10px 0 0;"
            type="success"
          >
            {{ tag.title }}
          </el-button>
        </div>
      </MainPanel>
    </div>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import ImageViewer from '@/components/common/ImageViewer.vue';

export default {
  name: 'ContractorAdditionalInfo',
  components: {ImageViewer, MainPanel },
  props: {
    contractor: {type: Object, required: true},
  },
  data() {
    return {
      contractorsServices: null,
      portfolioImages: null,
      portfolioVideos: null,
      workExamples: null,
      tags: null,
      adminStatusesLoaded: false,
      loading: false,
    }
  },
  async created() {
    await this.getContractorInfo();
  },
  methods: {
    async getContractorInfo() {
      this.loading = true;
      this.contractorsServices = this.contractor.contractors_services
      this.portfolioImages = this.contractor.portfolio?.portfolio_images
      this.portfolioVideos = this.contractor.portfolio?.video_links
      this.workExamples = this.contractor.work_examples
      this.tags = this.contractor.tags
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.contractors-services {
  margin-bottom: 20px;
}
</style>


