<template>
  <div v-if="contractor">
    <PageHeader link-name="Назад" link-path="ContractorsList" page-name="Исполнитель">
      <template>
        <el-button
          v-if="contractor.state !== 'ACTIVE'"
          type="success"
          @click="approveContractor"
        >
          Подтвердить
        </el-button>
        <el-button
          v-else
          type="disabled"
        >
          Подтвержден
        </el-button>

        <el-avatar
          v-if="contractor.user.avatar"
          shape="square"
          :size="50"
          :src="contractor.user.avatar"
        />
      </template>
    </PageHeader>
    <div class="common-info">
      <div class="user-info">
        <MainPanel v-loading="loading">
          <h2>Информация о пользователе</h2>
          <table v-if="contractor" class="user-data">
            <tbody>
              <tr>
                <td><strong>Имя</strong></td>
                <td>{{ contractor && contractor.user.show_name }}</td>
              </tr>
              <tr>
                <td><strong>ID</strong></td>
                <td>{{ contractor && contractor.user.id }}</td>
              </tr>
              <tr>
                <td><strong>Email</strong></td>
                <td>{{ contractor && contractor.user.contacts.email }}</td>
              </tr>
              <tr>
                <td><strong>Телефон</strong></td>
                <td>{{ contractor && contractor.user.contacts.phone }}</td>
              </tr>
              <tr>
                <td><strong>Был на сайте</strong></td>
                <td>{{ contractor.user && contractor.user.last_visited_at }}</td>
              </tr>
              <tr>
                <td><strong>Профиль</strong></td>
                <td>
                  <button
                    class="el-button"
                    @click="onView(contractor.user.id)"
                  >
                    Просмотреть профиль
                  </button>
                </td>
              </tr>
              <tr>
                <td><strong>Категория</strong></td>
                <td>
                  <el-select
                    ref="categorySelect"
                    :filter-method="filterMethod"
                    filterable
                    no-data-text="Ничего не найдено"
                    placeholder="Виберите категорию"
                    :value="selectedCategoryId"
                    @change="onChangeCategory"
                    @visible-change="onCategorySelectToggleDropdown"
                  >
                    <el-option-group
                      v-for="group in filteredCategories"
                      :key="group.id"
                      :label="group.name"
                    >
                      <el-option
                        v-for="category in group.children"
                        :key="category.id"
                        :label="category.name"
                        :value="category.id"
                      />
                    </el-option-group>
                  </el-select>
                </td>
              </tr>
            </tbody>
          </table>
        </MainPanel>
      </div>
      <div class="contractor-info">
        <ContractorMainInfo :contractor="contractor" />
      </div>
    </div>
    <ContractorAdditionalInfo :contractor="contractor" />
    <UserPublications :user="contractor.user" />
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex'
import PageHeader from '@/components/common/PageHeader';
import ContractorAdditionalInfo from '@/components/contractors/ContractorAdditionalInfo.vue';
import UserPublications from '@/components/users/UserPublications.vue';
import ContractorMainInfo from '@/components/contractors/ContractorMainInfo.vue';


export default {
  metaInfo: {
    title: 'Исполнитель',
  },
  components: {ContractorMainInfo, UserPublications, ContractorAdditionalInfo, MainPanel, PageHeader},
  data() {
    return {
      contractor: null,
      localAdminStatuses: {'DEFAULT': 'Без статусу'},
      categories: [],
      filteredCategories: [],
      selectedCategoryId: null,
      loading: false,
    }
  },
  async created() {
    await this.getContractor()
    await this.getCategories()
  },
  methods: {
    ...mapActions('contractors', [
      'fetchContractor',
      'changeContractorState',
      'fetchContractorCategories',
      'updateContractorCategory',
    ]),

    async getContractor() {
      this.contractor = await this.fetchContractor(this.$route.params.id)
      this.selectedCategoryId = this.contractor.category?.id
    },

    onCategorySelectToggleDropdown() {
      this.filteredCategories = this.categories
    },

    async getCategories() {
      this.categories = await this.fetchContractorCategories()
      this.filteredCategories = this.categories
    },

    filterMethod(val) {
      if (!val) {
        this.filteredCategories = this.categories
      }
      const filteredGroups = this.categories.filter(group => group.name.toLowerCase().includes(val.toLowerCase()))
      const filteredChildren = this.categories.reduce((acc, group) => {
        const children = group.children?.filter(category => {
          return category.name.toLowerCase().includes(val.toLowerCase())
        })

        const isGroupAlreadyExist = filteredGroups.some(g => g.id === group.id)

        if (children.length > 0 && !isGroupAlreadyExist) {
          acc = [...acc, {
            id: group.id,
            name: group.name,
            children,
          }]
        }
        return acc
      }, [])

      this.filteredCategories = [...filteredGroups, ...filteredChildren]
    },

    async onChangeCategory(categoryId) {
      try {
        if (this.contractor.category) {
          const newCategoryName = this.categories.reduce((acc, group) => {
            const name = group.children.find(cat => cat.id === categoryId)?.name
            if (name) {
              acc = name
            }
            return acc
          }, '')
          const confirmation = await this.$confirm(`У этого исполнителя уже есть категория: "${this.contractor.category.name}". Вы действительно хотите поменять категорию на "${newCategoryName}"?`, {
            cancelButtonText: 'Нет',
            confirmButtonText: 'Да',
            type: 'warning',
            center: true,
          })
          if (!confirmation) {
            return
          }
        }

        try {
          await this.updateContractorCategory({
            contractorId: this.contractor.id,
            categoryId: categoryId,
          })
          this.selectedCategoryId = categoryId
          if (this.contractor.category) {
            await this.$message.success('Категория успешно изменена!');
          } else {
            await this.$message.success('Категория успешно добавлена!');
          }
          this.getContractor()
        } catch (e) {
          await this.$message.error('Категорию добавить не удалось!');
        }
      }
      catch {}
      finally {
        this.$refs.categorySelect.blur()
        this.filteredCategories = this.categories
      }
    },

    onView(id) {
      const url = this.$router.resolve({ name: 'ViewUser', params: { id } }).href;
      window.open(url, '_blank');
    },

    async approveContractor() {
      try {
        await this.changeContractorState(this.contractor.id)
        await this.$router.go(0)
        this.$message.success('Исполнитель успешно подтвержден')
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.big-avatar-wrapper
  display: flex
  align-items: center


.big-avatar
  height: 90px
  width: 90px
  margin: 6px 20px 6px 6px
  border: 6px solid $color-bg
  box-shadow: 0 0 0 6px $color-text-accent-customer
  border-radius: 50%

  &.big-avatar--business
    border: 6px solid #ffffff
    box-shadow: 0 0 0 6px $color-text-accent-business

.user-data
  td
    padding: 10px 10px
    border-bottom: 1px solid #f1f1f1

  tr
    &:last-of-type
      td
        border-bottom: none

.document
  display: flex
  align-items: center
  font-size: 12px
  padding-bottom: 5px
  padding-top: 5px

.common-info
  display: flex
  align-items: flex-start

.user-info
  flex: 1
  min-width: 400px
  padding-right: 10px

.contractor-info
  flex-basis: 100%

.contractors-services
  padding-top: 10px

.comparing-table__label
  padding-top: 5px
  margin-bottom: 4px
  font-weight: 600

  i
    font-size: 20px
    margin-right: 7px
</style>
