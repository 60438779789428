<template>
  <div v-if="contractor">
    <MainPanel v-loading="loading">
      <h2>Информация о исполнителе</h2>
      <table v-if="contractor" class="user-data">
        <tbody>
          <tr>
            <td><strong>Имя</strong></td>
            <td>{{ contractor && contractor.user.show_name }}</td>
          </tr>
          <tr>
            <td><strong>ID</strong></td>
            <td>{{ contractor && contractor.id }}</td>
          </tr>
          <tr>
            <td><strong>Описание</strong></td>
            <td>{{ contractor && contractor.description }}</td>
          </tr>
          <tr>
            <td><strong>Дата создания</strong></td>
            <td>{{ contractor && contractor.created_at }}</td>
          </tr>
          <tr>
            <td><strong>Ссылка на сайт</strong></td>
            <td><a :href="contractor.frontend_url" target="_blank">{{ contractor.frontend_url }}</a></td>
          </tr>
          <tr>
            <td><strong>Админ. статус</strong></td>
            <td>
              <el-dropdown
                class="btn-item"
                trigger="click"
                @command="(command) => changeStatus(command)"
              >
                <span class="el-dropdown-link">
                  {{ contractor.admin_status || 'Выберите статус' }}
                  <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(value, key) in adminStatuses"
                    :key="key"
                    :command="value"
                  >
                    {{ value }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import {mapActions, mapGetters} from 'vuex';


export default {
  metaInfo: {
    title: 'Исполнитель',
  },
  components: {MainPanel},
  props: {
    contractor: {type: Object, required: true},
  },
  data() {
    return {
      localAdminStatuses: [{'DEFAULT': 'Без статусу'}],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('contractors', [
      'contractorAdminStatusesTransformed',
    ]),
    adminStatuses() {
      return this.localAdminStatuses.reduce((acc, status) => {
        acc[status.key] = status.label;
        return acc;
      }, {});
    },
  },
  async created() {
    await this.loadAdminStatuses()
  },
  methods: {
    ...mapActions('contractors', [
      'fetchContractorAdminStatuses',
      'saveAdminStatus',
    ]),

    async loadAdminStatuses() {
      if (!this.adminStatusesLoaded) {
        await this.fetchContractorAdminStatuses()
        this.localAdminStatuses = [
          { key: 'DEFAULT', label: 'Без статусу' },
          ...this.contractorAdminStatusesTransformed,
        ]
        this.adminStatusesLoaded = true
      }
    },

    changeStatus(status) {
      if (status === 'Без статусу') {
        status = null;
      }
      this.saveAdminStatus({id: this.contractor.id, status: status})
        .then(() => {
          this.$set(this.contractor, 'admin_status', status)

          this.$message({
            message: 'Статус успешно обновлен',
            type: 'success',
          })
        })
        .catch(error => {
          console.error('Ошибка при обновлении статуса:', error)
          this.$message({
            message: 'Ошибка при обновлении статуса',
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.user-data
  td
    padding: 10px 10px
    border-bottom: 1px solid #f1f1f1

  tr
    &:last-of-type
      td
        border-bottom: none

.contractor-info
  flex-basis: 100%

</style>
