<template>
  <el-table
    :data="list && list.data"
    :default-sort="{prop: 'created_at', order: 'descending'}"
    stripe
    style="width: 100%"
    @sort-change="onSortChange"
  >
    <el-table-column label="Исполнитель">
      <template slot-scope="scope">
        <ContractorCard :contractor="scope.row" />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Пользователь"
    >
      <template slot-scope="scope">
        <el-button
          size="small"
          type="info"
          @click="onView(scope.row.user.id)"
        >
          <i class="far fa-eye" />
        </el-button>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Дата создания"
      prop="created_at"
      sortable="custom"
    />

    <el-table-column
      align="center"
      label="Дата последнего визита"
      prop="last_visited"
      sortable="custom"
    />

    <el-table-column
      align="center"
      label="Email"
      min-width="100"
      prop="email"
    />

    <el-table-column
      align="center"
      label="Телефон"
      prop="phone"
    />

    <el-table-column
      align="center"
      label="Статус"
      prop="state"
    />

    <el-table-column
      align="center"
      label="Админ. статус"
      width="170"
    >
      <template slot-scope="scope">
        <el-dropdown
          class="btn-item"
          trigger="click"
          @command="(command) => changeStatus(command, scope.row)"
        >
          <span class="el-dropdown-link">
            {{ scope.row.admin_status || 'Выберите статус' }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key) in adminStatuses"
              :key="key"
              :command="value"
            >
              {{ value }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Добавить ( НЕ изменить ) номера телефона"
    >
      <template slot-scope="scope">
        <el-button
          circle
          icon="el-icon-edit"
          size="small"
          type="warning"
          @click="openModal(scope.row.phone, scope.row.id)"
        />
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      fixed="right"
      label="Просмотр на сайте"
      width="170"
    >
      <template slot-scope="scope">
        <a :href="scope.row.frontend_url" target="_blank">
          <el-button
            v-show="scope.row.frontend_url"
            size="small"
            type="info"
          >
            <i class="fa fa-file" />
          </el-button>
        </a>
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      label="Удалить исполнителя"
    >
      <template slot-scope="scope">
        <el-button
          circle
          icon="el-icon-delete"
          size="small"
          type="danger"
          @click="removeContractor(scope.row.id)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ContractorCard from '@/components/contractor-card/ContractorCard';


export default {
  name: 'List',
  components: { ContractorCard},
  data() {
    return {
      removeConfirmation: false,
      modalVisible: false,
      selectedPhone: '',
      selectedId: '',
      localAdminStatuses: [
        { key: 'DEFAULT', label: 'Без статусу' },
      ],
    }
  },
  computed: {
    ...mapState('contractors', {
      list: 'contractors',
    }),
    ...mapGetters('contractors', [
      'contractorAdminStatusesTransformed',
    ]),
    adminStatuses() {
      return this.localAdminStatuses.reduce((acc, status) => {
        acc[status.key] = status.label;
        return acc;
      }, {});
    },
  },
  async created() {
    await this.fetchContractorStates()
    await this.loadAdminStatuses()
  },
  methods: {
    ...mapActions('contractors', [
      'fetchContractorStates',
      'deleteContractor',
      'saveAdminStatus',
      'fetchContractorAdminStatuses',
      ]),
    async loadAdminStatuses() {
      if (!this.adminStatusesLoaded) {
        await this.fetchContractorAdminStatuses()
        this.localAdminStatuses = [
          { key: 'DEFAULT', label: 'Без статусу' },
          ...this.contractorAdminStatusesTransformed,
        ]
        this.adminStatusesLoaded = true
      }
    },
    onView(id) {
      this.$router.push({name: 'ViewUser', params: {id}})
    },
    openModal(phone, id) {
      this.$emit('openModal', { phone,id });
    },
    onSortChange(val) {
      if (val.prop === 'created_at') {
        this.$emit('sort', {
          sort: val.order === 'ascending' ? 'oldest' : 'newest',
        })
      }
    },
    async removeContractor(id) {
      try {
        this.removeConfirmation = await this.$confirm('Вы действительно хотите удалить этого исполнителя?', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
          type: 'warning',
          center: true,
        })
      } catch (e) {
        console.error(e)
      }

      if (!this.removeConfirmation) {
        return
      }
      try {
        await this.deleteContractor(id)
        await this.$message.success('Исполнитель успешно  удален!');
      } catch (e) {
        await this.$message.error('Исполнителя НЕ удалось удалить!)');
      }
    },
    changeStatus(status, row) {
      if (status === 'Без статусу') {
        status = null;
      }
      this.saveAdminStatus({id: row.id, status: status})
        .then(() => {
          this.$set(row, 'admin_status', status)

          this.$message({
            message: 'Статус успешно обновлен',
            type: 'success',
          })
        })
        .catch(error => {
          console.error('Ошибка при обновлении статуса:', error)
          this.$message({
            message: 'Ошибка при обновлении статуса',
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"


.type-tag
  text-transform: uppercase

.name-wrapper
  display: flex
  align-items: center

.el-button-group
  .el-button
    padding-top: 4px
    padding-bottom: 4px

    &:first-of-type
      border-right: 0

    &:last-of-type
      border-left: 0

</style>
